import React from 'react';
import Container from '../../common/components/UI/Container';
import BlogPost from '../../common/components/BlogPost';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import { Section, SectionHeading, FeatureWrapper } from './features.style';

import { data } from '../../data/index';

const Features = () => {
  return (
    <Section id="features">
      <Container noGutter width="1300px">
        <SectionHeading>
          <Heading content="About Our Scholarship Test" />
          <Text content="Learn more about what our test offers" />
        </SectionHeading>
        <FeatureWrapper>
          {data.features.map((feature) => (
            <BlogPost
              className="feature-item"
              key={feature.id}
              thumbUrl={feature.icon}
              title={feature.title}
              excerpt={feature.desc}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default Features;
