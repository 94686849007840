import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Section = styled.section `
  padding: 80px 0;
  @media only screen and (max-width: 768px) {
    padding-bottom: 50px;
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

export const SectionHeading = styled.div `
  margin: 0 auto 50px;
  max-width: 675px;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 75px;
  }
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.29;
    letter-spacing: -0.5px;
    color: ${ themeGet( 'colors.textPrimary' ) };
    @media only screen and (max-width: 480px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 30px;
    line-height: 2.19;
    color: ${ themeGet( 'colors.text' ) };
    @media only screen and (max-width: 480px) {
      line-height: 1.9;
    }
  }
`;

export const FeatureWrapper = styled.div `
display : flex;
flex-direction : row;
gap : 1rem;
align-items :center;

@media only screen and (max-width: 480px) {
flex-direction : column;
    }
    
.feature-item{
  display : flex;
  flex-direction :column;
  align-items:center;
  padding: 0px 15px;
}

.content {
  text-align : center;
}

.excerpt{
  font-weight: 400;
    font-size: 18px;
    color : #4F4F4F;
    }

    .title{
      font-weight: 600;
    font-size: 24px;
    }
`;