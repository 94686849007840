////import Screenshot1 from '../../assets/image/saasClassic/screen-1.png';
import feature1 from '../../src/common/assets/feature1.png'
import feature2 from '../../src/common/assets/feature2.png'
import feature3 from '../../src/common/assets/feature3.png'
import feature4 from '../../src/common/assets/feature4.png'

import featuresTwo1 from '../../src/common/assets/featuresTwo1.png'
import featuresTwo2 from '../../src/common/assets/featuresTwo2.png'
import featuresTwo3 from '../../src/common/assets/featuresTwo3.png'

//import AuthorOne from '../common/assets/author-2.jpg';
//import AuthorTwo from '../common/assets/author-2.jpg';
//import step1 from '../common/assets/step1.png';
//import step2 from '../common/assets/step2.png';
//import step3 from '../common/assets/step3.png';
//import feature1 from '../common/assets/Features/feature1.png';
//import feature2 from '../common/assets/Features/feature2.png';
//import feature3 from '../common/assets/Features/feature3.png';
//import feature4 from '../common/assets/Features/feature4.png';
//import feature5 from '../common/assets/Features/feature5.png';
//import feature6 from '../common/assets/Features/feature6.png';

//import stridelogo from '../common/assets/strideLogo.png'
////import AuthorThree from '../../assets/image/saasClassic/author-3.jpg';

//import Logo from '../common/assets/sbit-logo-white.png';
//import appleStore from '../common/assets/apple-store.png';
//import googlePlay from '../common/assets/google-play.png';

//import sanya from '../common/assets/Testimonials/sanya.png';
//import Anamika from '../common/assets/Testimonials/Anamika.png';
//import Vishakha from '../common/assets/Testimonials/Vishakha.png';

export const Timeline = [
  {
    title: 'Quick Assessment',
    description:
      'Begin with a brief, intuitive evaluation across subjects designed to identify your strengths and areas for improvement.',
  },
  {
    title: 'Calculation & Analysis',
    description:
      'Your responses will be thoroughly analyzed to determine your scholarship potential with precision and fairness.',
  },
  {
    title: 'Personalised Insights',
    description:
      'Our counsellors will provide personalized insights and guidance based on your performance.',
  },
];

export const stats = [{
  id: 1,
  title: 'Full time',
  text: `Practical learning`,
  linkLabel: 'Start Earning',
  link: '#',
},
{
  id: 2,
  title: '3 years',
  text: 'Offline at Chennai',
  linkLabel: 'Sign up your store',
  link: '#',
},
{
  id: 3,
  title: '3 curated',
  text: `Specialisations`,
  linkLabel: 'Get the app',
  link: '#',
},
{
  id: 4,
  title: 'High impact',
  text: `Placement support`,
  linkLabel: 'Start Earning',
  link: '#',
},
];

export const FAQ_DATA = [
  {
    id: 1,
    expend: true,
    title: 'How do I start the Scholarship Test?',
    description:
      'To start the Scholarship Test, simply click the "Start Assessment" button on the landing page and follow the instructions provided.',
  },
  {
    id: 2,
    title: 'How will I know if I qualify for a scholarship?',
    description:
      'If you qualify for a scholarship, you will be notified by our team. You can also check your score  after completing the test.',
  },
  {
    id: 3,
    title: 'What happens after I complete the test?',
    description:
      'After you complete the test, you can schedule a call with a counselor. They will provide personalized insights based on your test results.',
  },
  {
    id: 4,
    title: 'What kind of financial aid can I receive?',
    description:
      'The financial aid you can receive depends on your performance in the test. We offer a range of scholarships that can significantly reduce your educational expenses.',
  },
  {
    id: 4,
    title: 'Is the test process fair and transparent?',
    description:
      "Yes, our scholarship test process is designed to be transparent and fair, ensuring every participant has an equal opportunity to succeed. We provide immediate feedback and ensure that the evaluation criteria are clear and consistent.",
  },
];
//export const bannerData = {
//  news: 'NEWS',
//  newsLabel: 'We have updated our term & condition policy',
//  title: <>Boost your chances to get into top universities with JenNext Mentors:<br /> Gain Expert Advice Today!</>,
//  text: <>Feeling Overwhelmed? Our <strong> Expert Mentors</strong> Simplify Your Journey to Global Education. From profile evaluation to scholarship support, JenNext is your one-stop solution for all things study abroad.</>,
//  button: [
//    {
//      id: 1,
//      title: 'Google Play',
//      thumb: googlePlay,
//      link: 'https://play.google.com/store',
//    },
//    {
//      id: 2,
//      title: 'App Store',
//      thumb: appleStore,
//      link: 'https://www.apple.com/sg/app-store/',
//    },
//  ],
//};

//export const FOOTER_DATA = {
//  strideLogo: stridelogo,
//  logo: Logo,
//  menu: [
//    {
//      link: '#',
//      label: 'Support',
//    },
//    {
//      link: '#',
//      label: 'About Us',
//    },
//    {
//      link: '#',
//      label: 'Privacy',
//    },
//    {
//      link: '#',
//      label: 'Contact',
//    },
//  ],
//  social: [
//    {
//      icon: Logo,
//      link: '#',
//    },
//  ],
//};


////export const MENU_ITEMS = [{
////        label: 'Home',
////        path: '#banner_section',
////        offset: '0',
////    },
////    {
////        label: 'Screenshot',
////        path: '#screenshot_section',
////        offset: '0',
////    },
////    {
////        label: 'Feature',
////        path: '#feature_section',
////        offset: '0',
////    },
////    {
////        label: 'Pricing',
////        path: '#pricing_section',
////        offset: '0',
////    },
////    {
////        label: 'Testimonial',
////        path: '#testimonial_section',
////        offset: '0',
////    },
////];

////export const SERVICE_ITEMS = [{
////        icon: 'flaticon-stopwatch-1',
////        title: 'Fast Performance',
////    },
////    {
////        icon: 'flaticon-prototype',
////        title: 'Prototyping',
////    },
////    {
////        icon: 'flaticon-code',
////        title: 'Coade Export',
////    },
////    {
////        icon: 'flaticon-vectors',
////        title: 'Vector Editing',
////    },
////    {
////        icon: 'flaticon-export',
////        title: 'Export Presets',
////    },
////];

//export const MONTHLY_PRICING_TABLE = [{
//  name: 'Step 1',
//  description: <><span style={ { color: '#182C59' } }>Take the Assessment</span></>,
//  price: (
//    <>
//      Begin your journey! Take out 2 minutes of your time to take our assessment and figure out your Career Readiness.<br /><br /><br /><br />
//      <img src={ step1 } />
//    </>
//  ),
//},
//{
//  name: 'Step 2',
//  description: <><span style={ { color: '#182C59' } }>Receive your personalized Report</span></>,
//  price: (
//    <>
//      Get a clear indication of your preparedness for a highly employable career. Our comprehensive report outlines your performance across different areas.<br /><br /><br /><br />
//      <img src={ step2 } />
//    </>
//  ),
//},
//{
//  name: 'Step 3',
//  description: <><span style={ { color: '#182C59' } }>Expert Counseling & Recommendations</span></>,
//  price: (
//    <>
//      The most crucial step - schedule a one-on-one session with our expert mentors to discuss your assessment results and receive tailored advice for your  journey ahead.<br /><br /><br /><br />
//      <img src={ step3 } />
//    </>
//  ),
//},
//];

//export const YEARLY_PRICING_TABLE = [{
//  name: 'Basic Account',
//  description: 'For a single client or team who need to build website ',
//  price: '$0',
//  priceLabel: 'Only for first month',
//  buttonLabel: 'Start for free',
//  url: '#',
//  listItems: [{
//    content: 'Drag & Drop Builder',
//  },
//  {
//    content: '1,000s of Templates Ready',
//  },
//  {
//    content: 'Blog Tools',
//  },
//  {
//    content: 'eCommerce Store ',
//  },
//  {
//    content: '30+ Webmaster Tools',
//  },
//  ],
//},
//{
//  name: 'Business Account',
//  description: 'For Small teams or group who need to build website ',
//  price: '$6.00',
//  priceLabel: 'Per month & subscription yearly',
//  buttonLabel: 'Register Now',
//  url: '#',
//  trialButtonLabel: 'Or Start 14 Days trail',
//  trialURL: '#',
//  listItems: [{
//    content: 'Unlimited secure storage',
//  },
//  {
//    content: '2,000s of Templates Ready',
//  },
//  {
//    content: 'Blog Tools',
//  },
//  {
//    content: '24/7 phone support',
//  },
//  {
//    content: '50+ Webmaster Tools',
//  },
//  ],
//},
//{
//  name: 'Premium Account',
//  description: 'For Large teams or group who need to build website ',
//  price: '$9.99',
//  priceLabel: 'Per month & subscription yearly',
//  buttonLabel: 'Register Now',
//  url: '#',
//  trialButtonLabel: 'Or Start 14 Days trail',
//  trialURL: '#',
//  listItems: [{
//    content: 'Drag & Drop Builder',
//  },
//  {
//    content: '3,000s of Templates Ready',
//  },
//  {
//    content: 'Advanced branding',
//  },
//  {
//    content: 'Knowledge base support',
//  },
//  {
//    content: '80+ Webmaster Tools',
//  },
//  ],
//},
//];

////export const FAQ_DATA = [{
////        expend: true,
////        title: 'How to contact with Customer Service?',
////        description: 'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
////    },
////    {
////        title: 'App installation failed, how to update system information?',
////        description: 'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
////    },
////    {
////        title: 'Website reponse taking time, how to improve?',
////        description: 'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
////    },
////    {
////        title: 'New update fixed all bug and issues?',
////        description: 'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
////    },
////];

////export const FOOTER_WIDGET = [{
////        title: 'About Us',
////        menuItems: [{
////                url: '#',
////                text: 'Support Center',
////            },
////            {
////                url: '#',
////                text: 'Customer Support',
////            },
////            {
////                url: '#',
////                text: 'About Us',
////            },
////            {
////                url: '#',
////                text: 'Copyright',
////            },
////            {
////                url: '#',
////                text: 'Popular Campaign',
////            },
////        ],
////    },
////    {
////        title: 'Our Information',
////        menuItems: [{
////                url: '#',
////                text: 'Return Policy',
////            },
////            {
////                url: '#',
////                text: 'Privacy Policy',
////            },
////            {
////                url: '#',
////                text: 'Terms & Conditions',
////            },
////            {
////                url: '#',
////                text: 'Site Map',
////            },
////            {
////                url: '#',
////                text: 'Store Hours',
////            },
////        ],
////    },
////    {
////        title: 'My Account',
////        menuItems: [{
////                url: '#',
////                text: 'Press inquiries',
////            },
////            {
////                url: '#',
////                text: 'Social media directories',
////            },
////            {
////                url: '#',
////                text: 'Images & B-roll',
////            },
////            {
////                url: '#',
////                text: 'Permissions',
////            },
////            {
////                url: '#',
////                text: 'Speaker requests',
////            },
////        ],
////    },
////    {
////        title: 'Policy',
////        menuItems: [{
////                url: '#',
////                text: 'Application security',
////            },
////            {
////                url: '#',
////                text: 'Software principles',
////            },
////            {
////                url: '#',
////                text: 'Unwanted software policy',
////            },
////            {
////                url: '#',
////                text: 'Responsible supply chain',
////            },
////        ],
////    },
////];

//export const FEATURES = [{
//  icon: feature1,
//  title: 'Complete Profile Building',
//  description: 'Our assessment offers a 360-degree view of your Traits, providing you with a certificate and a free mentorship call.',
//},
//{
//  icon: feature2,
//  title: 'Your Career Readiness Score',
//  description: 'Receive detailed feedback. Understand where you stand and what it takes to elevate you to the next level.',
//},
//{
//  icon: feature3,
//  title: 'Actionable Insights',
//  description: 'Gain recommendations and valuable insights to enhance your readiness for a better career.',
//},
//];

//export const STATS = [{
//  icon: feature4,
//  title: 'What after 12th?',
//  description: 'Are you curious about what comes next after 12th? Take our quiz to get clear next steps to get a degree you love.',
//},
//{
//  icon: feature5,
//  title: 'You love all subjects ',
//  description: 'You’re confused about what to choose. Take our quiz to gain insights and get clarity.',
//},
//{
//  icon: feature6,
//  title: 'You Took Drop after 12th',
//  description: 'Unsure about your next move? Take our quiz to gain insights into potential career paths that align with your interest.',
//},
//];

////export const SCREENSHOTS = [{
////        icon: ic_monetization_on,
////        title: 'Budget Overview',
////        image: Screenshot1,
////    },
////    {
////        icon: ic_settings,
////        title: 'Create & Adjust',
////        image: Screenshot1,
////    },
////    {
////        icon: pieChart,
////        title: 'View Reports',
////        image: Screenshot1,
////    },
////    {
////        icon: briefcase,
////        title: 'Integrations',
////        image: Screenshot1,
////    },
////];

//export const TESTIMONIALS = [
//  {
//    title: 'About The Test',
//    review: (
//      <>
//        <br /><br /> <br />
//        {/*<img style={ { paddingLeft: '60px', paddingTop: '15px' } } src={ testiImage } alt="Testimonial Image" />*/ }
//        <strong>• Text Format : </strong> 15 questions <br /> <br />
//        <strong>• Question Format : </strong> MCQ <br /> <br />
//        <strong>• Duration : </strong> 5 Minutes <br /> <br />
//        <strong>• Level :</strong> Begineer as well as Intermediate<br /> <br />
//        <br /> <br /><br />
//        The test offers a series of thought-provoking questions, crafted to evaluate essential competencies such as your communication skills,
//        strategic thinking abilities, and leadership qualities.
//      </>
//    ),
//    name: 'Jon Doe',
//    designation: 'CEO of Dell Co.',
//    avatar: AuthorOne,
//  },
//  {
//    title: 'Who is This Test for?',
//    review: (
//      <>
//        <strong>• Aspiring Managers:</strong> For those dreaming of leading teams and driving business success.<br /> <br />
//        <strong>• Career Changers:</strong> Considering a switch to management? This test will guide your decision.<br /> <br />
//        <strong>• Recent Graduates:</strong> Fresh out of college and pondering a management career? Start here.<br /> <br />
//        <strong>• Entrepreneurial Minds:</strong>If you're planning to start your own business, assess your management acumen.<br /> <br />
//        <strong>• Professionals Seeking Growth:</strong>Elevate your career by understanding your potential in management roles.<br /> <br />
//        <strong>• Curious Minds:</strong>Anyone curious about what a management career entails.
//      </>
//    ),
//    name: 'Jon Doe',
//    designation: 'Co Founder of IBM',
//    avatar: AuthorTwo,
//  },
//];


//export const USERTESTIMONIALS = [
//  {
//    review:
//      'Starting my journey at SBIT as a first-year student has been an incredible experience. The welcoming atmosphere, dedicated professors, and exciting opportunities have made me feel right at home from day one. I\'m eager to embrace all that this vibrant community has to offer and excited to see where this journey will take me.',
//    name: 'Sanya Aarya',
//    designation: 'B.Tech, Computer Science and Engineering, Class of 2027',
//    avatar: sanya,
//  },
//  {
//    review:
//      'I\'m grateful for the opportunities to collaborate, innovate, and make a meaningful impact both inside and outside the classroom. SBIT has truly equipped me with the skills and confidence to succeed in whatever path I choose.',
//    name: 'Vishakha',
//    designation: 'B.Tech, Computer Science and Engineering Class of 2026',
//    avatar: Vishakha,
//  },
//  {
//    review:
//      'The supportive faculty, dynamic learning environment, and industry interaction have truly shaped my academic and personal growth.',
//    name: 'Anamika Ahuja',
//    designation: 'B.Tech, Computer Science and Engineering Class of 2027',
//    avatar: Anamika,
//  },
//];

export const data = {
  features: [
    {
      id: 1,
      icon: feature1,
      title: 'Comprehensive Assessment',
      desc: `Evaluate abilities, understand strengths, and identify areas for improvement`,
    },
    {
      id: 2,
      icon: feature2,
      title: 'Tailored Scholarship Opportunities',
      desc: `Personalized offers maximize financial aid based on individual performance.`,
    },
    {
      id: 3,
      icon: feature3,
      title: <>Transparent <br /> Process</>,
      desc: `The process offers counselors' insights and feedback on eligibility.`,
    },
    {
      id: 4,
      icon: feature4,
      title: 'Commitment to Excellence',
      desc: `Crafted for fairness and accuracy, empowering confident educational pursuit.`,
    },
  ],
};



export const data2 = {
  features: [
    {
      id: 1,
      icon: featuresTwo1,
      title: 'Unlock Financial Aid',
      desc: `Secure scholarships tailored to your academic performance, providing vital financial support.`,
    },
    {
      id: 2,
      icon: featuresTwo2,
      title: 'Boost Your Confidence',
      desc: `Achieve a sense of accomplishment and confidence by taking our scholarship test and seeing your potential recognized.`,
    },
    {
      id: 3,
      icon: featuresTwo3,
      title: 'Transparent and Fair',
      desc: `Experience a transparent and accurate process with counselors providing insights and scholarship offers`,
    },
  ],
};
