import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const CopyrightContainer = styled.div `
display : flex;
justify-content: center;
margin-bottom : 4rem;
span {
      color: #E6DCCA;
      font-weight: 400;
      font-size: 15px;
      text-align:center;
}
`

export const ImageContainer = styled.div `
width : 100%;
display : flex;
justify-content : center;
padding : 1rem;
`

export const FooterArea = styled.footer `
    background-color : #0C2143;
    width : "1300px";
  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 35px;
    padding-bottom: 35px;
    justify-content: space-between;
    @media (max-width: 1600px) {
      padding-bottom: 40px;
    }
    @media (max-width: 991px) {
      flex-direction: column;
    gap: 1rem;
    }
  }
  .logoBox {
    display: flex;
    align-items: center;
    max-width : 25rem;
    @media (max-width: 425px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .logo {
      @media (max-width: 425px) {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
    p {
      margin: 0;
      color: #E6DCCA;
      margin-left: 15px;
      margin-right: 5px;
      @media (max-width: 1600px) {
        font-size: 15px;
      }
    }
    span{
      color: #E6DCCA;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .footerLink {
    line-height: 1;
    color: ${ themeGet( 'colors.primary' ) };
    font-size: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid currentColor;
  }
  .menu {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    a {
      font-size: 15px;
      line-height: 1;
      color: #0f2137;
      transition: all 500ms ease;
      &:hover {
        color: ${ themeGet( 'colors.primary' ) };
      }
      + a {
        margin-left: 35px;
      }
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #E6DCCA;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 15px;
      display: block;
      @media (max-width: 525px) {
        text-align : center;
        
      }
    }
    a {
      margin-left: 15px;
      transition: opacity 0.4s ease;
      &:hover {
        opacity: .8;
      }
    }
    @media (max-width: 525px) {
        text-align : center;
      }
  }
`;