import styled, { keyframes } from 'styled-components';

export const NavbarWrapper = styled.section `
  padding: 70px 0 0 0;
  background-image: linear-gradient(180deg, #294686 0%, #6692CB 38%, #6895CE 94%, white 99%);
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  @media (max-width: 575px) {
    padding: 50px 0 0 0;
  }
`;

export const BoxWrapper = styled.div `
display : flex;
width:100%;
justify-content:space-around;
align-items:center;

@media (max-width: 575px) {
  display : flex;
  flex-direction : column;
  gap:5px;
  justify-content : center;
  align-items : center;  
  }

`