import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Section = styled.section `
  padding-top: 60px;
  padding-bottom: 60px;
  background-color : #0C2143;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const SectionHeading = styled.div `
  text-align: center;
  max-width : 80%;
  h2 {
    font-weight: 800;
    font-size: 48px;
    line-height: 56px;
    color : #E6DCCA;
    letter-spacing: -0.5px;
    @media (min-width: 1024px) {
      font-size: 48px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 24px;
    font-weight: 400;
    color : #E6DCCA;
    @media only screen and (max-width: 480px) {
      line-height: 1.9;
      font-size: 18px;
    }
  }
`;

export const Grid = styled.div `
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 80px;
  @media (max-width: 1280px) {
    gap: 18px;
  }
  @media (max-width: 1024px) {
    gap: 20px;
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 290px);
    justify-content: center;
    gap: 50px 40px;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 60px;
  }
`;

export const Item = styled.div `
  text-align: center;
  background : #3c4b65;
  border-radius: 30px; 
  display : flex;
  flex-direction: column;
  gap:2rem;
  justify-content : center;
  padding : 50px 20px;
  figure {
    @media (min-width: 769px) and (max-width: 1024px) {
      min-height: auto;
      margin-bottom: 30px;
    }
    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  img {
    margin: 0 auto;
    @media (max-width: 1024px) {
      max-width: 70px;
    }
  }
  h4 {
    font-weight: 600;
    font-size: 44px;
    line-height: 25px;
    margin : 0px;
    color: #E6DCCA;
    @media (max-width: 1024px) {
      font-size: 17px;
    }
  }
  p {
    color: #E6DCCA;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.3;
    margin : 0px;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 15px;
      line-height: 1.9;
    }
  }
  a {
    color: ${ themeGet( 'colors.linkColor' ) };
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.1px;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 14px;
    }
    i {
      line-height: 1;
      transform: translateX(2px);
      transition: 0.3s ease 0s;
    }
    &:hover i {
      transform: translateX(5px);
    }
  }
`;

export default Section;