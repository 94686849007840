import React from 'react';
import Container from '../../common/components/UI/Container';
import BlogPost from '../../common/components/BlogPost';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import { Section, SectionHeading, FeatureWrapper } from './featuresTwo.style';

import { data2 } from '../../data/index';

const FeaturesTwo = () => {
  return (
    <Section id="features">
      <Container noGutter width="1300px">
        <SectionHeading>
          <Heading content="Why Take Our Scholarship Test?" />
        </SectionHeading>
        <FeatureWrapper>
          {data2.features.map((feature) => (
            <BlogPost
              className="feature-item"
              key={feature.id}
              thumbUrl={feature.icon}
              title={feature.title}
              excerpt={feature.desc}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default FeaturesTwo;
