import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import React from 'react';
import { FooterArea, CopyrightContainer, ImageContainer } from './footer.style';
import { Image } from 'antd';
import strideLogo from '../../common/assets/LVB stride logo Footer.png'

const Footer = () => {

  return (
    <FooterArea>
      <ImageContainer>
        <Image src={ strideLogo } />
      </ImageContainer>
      <Container>
        <Box className="logoBox">
          <Text as="span" content={ <>© 2024 Institute of Venture Building. All rights reserved</> } />
        </Box>
        <Box className="logoBox">
          <Text as="span" content={ <>7th Floor, EA Chambers Tower II, Whites Road, 49/50L, 5th St, Royapettah, Chennai, Tamil Nadu 600014</> } />
        </Box>
        {/*<Box className="logoBox">
          <Text as="span" content={ <>Faq</> } />
        </Box>*/}
        <Box className="logoBox">
          <Text as="span" content={ <> <span onClick={ () => window.open( 'https://www.buildwithivb.com/privacy-policy' ) }>Privacy policy</span></> } />
        </Box>
        <Box className="logoBox">
          <Text as="span" content={ <><span onClick={ () => window.open( 'https://www.buildwithivb.com/terms-and-conditions' ) }>Terms & Conditions</span></> } />
        </Box>
      </Container>
      <CopyrightContainer>
        {/*<Text as="span" content={ <><br />Copyright: © Copyright 2024 SBIT || All Rights Reserved.</> } />*/ }
      </CopyrightContainer>
    </FooterArea>
  );
};

export default Footer;
